import React from 'react';

import SVG from '../../components/SVG'

const zhLines = [
  276.96,
  367.39,
  457.81
];

const enLines = [
  276.6,
  346.28,
  413.96,
  481.64,
];

const isZh = /[^\x00-\x7F]+/

const Word = ({ by, line, ...props }) => {
  const splitted = line.split('|');
  const byIsZh = isZh.test(by)
  const lineIsZh = isZh.test(line)
  return (
    <SVG viewBox="0 -2 827 827" width={1} {...props}>
      <g>
        <g fill="none">
          <circle cx="411.99" cy="411.99" r="411.99"/>
          <circle cx="411.99" cy="411.99" r="411.49" stroke="#000"/>
        </g>
        <g>
          <g>
            <path d="M713.09 319.1c-10.91-52.86-62.36-117.45-148.77-88.1-91.72 31-105.71 12-142.34 39.15-34.4 25.45-24.89 70.19-35.23 88.37-22.94 40.83-51.74 39.71-56.49 74.66-3.64 28 13.42 35 27.4 91.45 14 56.21-11.46 118.85 28.25 134 27.68 10.34 54-20.42 85.85-46.7 0 0 35.23-28.8 104.86-35.24 6.44-.28 13.15-.56 20.7-.84 8.67.28 17.34 0 26-.28h1.4a82.81 82.81 0 0 0 14-.84c31.32-2 56.49-3.07 71.31-29.08 20.41-36.35-29.09-55.65-28.25-111.86.55-57.37 42.22-61.79 31.31-114.69z" fill="#e81e5c"/>
            <g>
              <g>
                <path d="M109.06 551.76h59.28v1.4h-59.28z"/>
              </g>
            </g>
            <g>
              <path d="M441 661.1a29.22 29.22 0 0 1-11.75-2.23c-25.17-9.51-24.89-38-24.33-71 .28-19.86.56-42.23-4.47-63.48a269.14 269.14 0 0 0-18.74-52.86c-6.43-14-10.62-23.21-8.67-38.87 2.52-19.57 12.87-28.24 24.89-38.31 10.07-8.67 21.53-18.18 31.6-36.63 3.36-5.87 4.48-14.54 5.87-24.89 2.8-20.69 6.16-46.42 29.65-63.76 19.3-14.26 32.16-15.94 53.41-18.73 19-2.52 45.3-5.88 89.21-20.7 33.83-11.46 65.71-9.23 92.28 5.87 34.12 19.58 52.29 55.65 57.88 83.06 5.88 28.52-3.35 43.34-13.14 59-8.67 14.27-17.9 28.8-18.18 55.09-.28 26.57 10.35 44.47 19.86 60.4 10.35 17.34 19.3 32.44 8.39 52-14.54 26-40 27.68-69.35 29.64l-2.8.28a83.92 83.92 0 0 1-14 .84h-1.4c-8.66.28-17.33.56-26 .28l-20.7.84c-68.51 6.43-103.74 35-104.3 35.23-5.6 4.76-11.19 9.51-16.5 14.26C478.47 644.6 460 661.1 441 661.1zm208.61-436.52c-13.14 0-26.84 2.51-41.39 7.27-44.22 14.82-70.47 18.15-89.76 20.69-21 2.8-33.56 4.2-52.3 18.18-22.65 16.78-26 41.95-28.8 62.36-1.4 10.35-2.51 19.3-6.15 25.73-10.34 18.73-22.09 28.52-32.16 37.19-12 10.35-21.53 18.46-24.05 37.19-1.95 14.83 2 23.49 8.39 37.76a273.8 273.8 0 0 1 19 53.13c5.31 21.53 5 44.18 4.75 64-.56 33.56-.84 60.12 22.93 69.07 22.1 8.39 43.35-10.35 68.24-32.16 5.31-4.75 10.9-9.51 16.5-14.26.28-.28 35.51-29.08 105.42-35.52 6.71-.28 13.7-.55 20.7-.83 8.67.28 17.34 0 26-.28h1.4a82.53 82.53 0 0 0 13.7-.84l3.08-.28c29.92-2 53.69-3.64 67.67-28.53 10.35-18.45 2.24-32.44-8.38-49.77-9.51-16.22-20.42-34.4-20.14-61.53.28-26.84 10.07-42.22 18.46-56.21 10.06-15.94 18.45-29.92 12.86-57.32C750 292.81 732.11 257 698.83 238a93.15 93.15 0 0 0-49.22-13.42z"/>
            </g>
          </g>
          <g>
            <path d="M235,725.3l-1.4,2.4l-6.8-4.1l-11.1,18.6l-2.8-1.7l11.1-18.6l-6.8-4.1l1.4-2.4L235,725.3z"/>
            <path d="M243.9,757.6l-3-1.5l5.1-9.9l-11.7-6.1l-5.1,9.9l-3-1.5l11.2-21.8l3,1.5l-5,9.7l11.7,6.1l5-9.7l3,1.5L243.9,757.6z"/>
            <path d="M273.9,744.1l-1.1,2.5l-10.8-4.7l-3.3,7.4l8.7,3.8l-1,2.4l-8.7-3.8l-3.4,7.7l10.8,4.7l-1.1,2.5l-13.8-6.1L260,738
              L273.9,744.1z"/>
            <path d="M294.3,755.9c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.3,0-0.5,0c-0.2-0.1-0.4-0.2-0.5-0.5c-0.2-0.3-0.4-0.6-0.7-0.9
              c-0.3-0.4-0.7-0.7-1.1-1.1c-0.5-0.4-1.1-0.7-1.8-1c-0.7-0.2-1.3-0.4-2-0.4c-0.6,0-1.1,0.1-1.6,0.3c-0.5,0.2-0.9,0.5-1.2,0.8
              c-0.3,0.4-0.6,0.8-0.8,1.3c-0.2,0.6-0.3,1.2-0.1,1.8c0.2,0.5,0.5,1,0.9,1.5c0.4,0.5,0.9,1,1.5,1.4c0.6,0.5,1.2,0.9,1.8,1.4
              c0.6,0.5,1.2,1,1.7,1.5c0.5,0.5,1,1.1,1.4,1.8c0.4,0.6,0.6,1.3,0.7,2.1c0.1,0.8,0,1.6-0.4,2.6c-0.4,1-0.9,1.9-1.5,2.7
              c-0.7,0.8-1.4,1.4-2.3,1.8c-0.9,0.4-1.9,0.6-3,0.7c-1.1,0-2.3-0.2-3.5-0.6c-1.5-0.5-2.8-1.3-3.8-2.3c-1-1-1.8-2.1-2.4-3.3l1.4-1.2
              c0.1-0.1,0.3-0.1,0.4-0.2c0.2,0,0.3,0,0.5,0c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.5,0.7,0.8,1.2c0.3,0.4,0.8,0.9,1.3,1.4
              c0.5,0.5,1.2,0.9,2.1,1.2c0.7,0.3,1.4,0.4,2.1,0.4c0.7,0,1.2-0.1,1.8-0.3c0.5-0.2,1-0.6,1.4-1c0.4-0.4,0.7-1,0.9-1.6
              c0.2-0.7,0.3-1.3,0.1-1.9c-0.2-0.6-0.4-1.1-0.8-1.6c-0.4-0.5-0.9-1-1.5-1.4c-0.6-0.4-1.2-0.9-1.8-1.4c-0.6-0.5-1.2-1-1.8-1.5
              c-0.6-0.5-1-1.1-1.4-1.8c-0.3-0.6-0.6-1.4-0.6-2.2c-0.1-0.8,0.1-1.7,0.4-2.7c0.3-0.8,0.7-1.5,1.3-2.2c0.6-0.6,1.3-1.2,2.1-1.5
              c0.8-0.4,1.7-0.6,2.7-0.6c1,0,2.1,0.2,3.2,0.6c1.3,0.5,2.4,1.1,3.3,1.9c0.9,0.8,1.6,1.7,2.1,2.8L294.3,755.9z"/>
            <path d="M312.5,783l-3.2-0.9l3.1-10.7l-12.7-3.7l-3.1,10.7l-3.2-0.9l6.9-23.5l3.2,0.9l-3.1,10.5l12.7,3.7l3.1-10.5l3.2,0.9
              L312.5,783z"/>
            <path d="M338.9,789l-2.5-0.6c-0.3-0.1-0.5-0.2-0.7-0.4c-0.1-0.2-0.2-0.4-0.3-0.6l-1-6.3l-10.7-2.4l-3.5,5.3
              c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.5,0.1-0.8,0.1l-2.5-0.6l14.9-21.8l3.3,0.7L338.9,789z M325.2,776.7l8.9,2l-1.6-10.6
              c-0.1-0.7-0.2-1.5-0.2-2.4c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.4-0.4,0.7-0.6,0.9L325.2,776.7z"/>
            <path d="M347.9,781.2l-1.4,9l-3.3-0.5l3.9-24.2l7.1,1.1c1.5,0.2,2.8,0.6,3.9,1.2c1.1,0.5,1.9,1.2,2.6,2c0.6,0.8,1.1,1.6,1.3,2.6
              c0.2,1,0.2,2,0.1,3.2c-0.2,1.1-0.5,2.1-1.1,3c-0.5,0.9-1.2,1.6-2.1,2.1c-0.9,0.6-1.9,0.9-3.1,1.1c-1.2,0.2-2.5,0.2-4-0.1
              L347.9,781.2z M348.3,778.6l3.9,0.6c0.9,0.1,1.8,0.2,2.5,0c0.8-0.1,1.4-0.4,2-0.7c0.6-0.4,1-0.8,1.4-1.4c0.3-0.6,0.6-1.2,0.7-2
              c0.2-1.5,0-2.8-0.8-3.8c-0.8-1-2.2-1.7-4.1-2l-3.9-0.6L348.3,778.6z"/>
            <path d="M381.8,770.1l-0.3,2.7l-11.7-1.2l-0.8,8.1l9.5,1l-0.3,2.6l-9.5-1l-0.9,8.3l11.7,1.2l-0.3,2.7l-15-1.6l2.5-24.4L381.8,770.1
              z"/>
            <path d="M414.8,783.5c0,1.8-0.3,3.5-0.9,5c-0.6,1.5-1.4,2.8-2.5,3.9c-1.1,1.1-2.4,1.9-3.8,2.5c-1.5,0.6-3.1,0.9-4.9,0.9
              c-1.8,0-3.4-0.3-4.9-1c-1.5-0.6-2.7-1.5-3.8-2.6c-1-1.1-1.9-2.4-2.4-4c-0.6-1.5-0.8-3.2-0.8-5.1c0-1.8,0.3-3.5,0.9-5
              c0.6-1.5,1.4-2.8,2.5-3.9c1.1-1.1,2.4-1.9,3.8-2.5c1.5-0.6,3.1-0.9,4.9-0.9c1.8,0,3.4,0.3,4.9,1c1.5,0.6,2.7,1.5,3.8,2.6
              c1,1.1,1.9,2.5,2.4,4C414.5,780,414.8,781.6,414.8,783.5z M411.4,783.4c0-1.5-0.2-2.9-0.6-4.1c-0.4-1.2-1-2.2-1.7-3.1
              c-0.7-0.8-1.6-1.5-2.7-1.9c-1.1-0.5-2.3-0.7-3.6-0.7c-1.3,0-2.5,0.2-3.6,0.6c-1.1,0.4-2,1.1-2.8,1.9c-0.8,0.8-1.4,1.8-1.8,3
              c-0.4,1.2-0.6,2.5-0.7,4c0,1.5,0.2,2.9,0.6,4c0.4,1.2,1,2.2,1.7,3c0.7,0.8,1.7,1.5,2.7,1.9c1.1,0.4,2.3,0.7,3.6,0.7
              c1.3,0,2.5-0.2,3.6-0.6c1.1-0.4,2-1.1,2.8-1.9c0.8-0.8,1.4-1.8,1.8-3C411.2,786.3,411.4,784.9,411.4,783.4z"/>
            <path d="M434.1,770.6l0.1,2.7l-11.8,0.5l0.3,8.6l10-0.4l0.1,2.7l-10,0.4l0.4,10.5l-3.3,0.1l-0.9-24.5L434.1,770.6z"/>
            <path d="M449.8,791l10.5-1.1l0.3,2.8l-13.8,1.5l-2.6-24.4l3.3-0.3L449.8,791z"/>
            <path d="M484.2,776.5c0.3,1.8,0.3,3.5,0,5.1c-0.3,1.6-0.9,3-1.8,4.3c-0.9,1.3-2,2.3-3.4,3.2c-1.4,0.8-2.9,1.4-4.7,1.7
              c-1.8,0.3-3.4,0.3-5-0.1c-1.6-0.4-3-1-4.2-1.9c-1.2-0.9-2.2-2.1-3.1-3.5c-0.8-1.4-1.4-3-1.7-4.8c-0.3-1.8-0.3-3.5,0-5.1
              c0.3-1.6,0.9-3,1.8-4.3c0.9-1.3,2-2.3,3.3-3.2c1.4-0.8,2.9-1.4,4.7-1.7c1.8-0.3,3.4-0.3,5,0.1c1.6,0.4,3,1,4.2,1.9
              c1.2,0.9,2.2,2.1,3.1,3.5C483.3,773.1,483.9,774.7,484.2,776.5z M480.8,777.1c-0.2-1.5-0.7-2.8-1.3-3.9c-0.6-1.1-1.3-2-2.2-2.7
              c-0.9-0.7-1.9-1.2-3-1.4c-1.1-0.3-2.3-0.3-3.6-0.1c-1.3,0.2-2.4,0.6-3.4,1.2c-1,0.6-1.8,1.4-2.4,2.3c-0.6,0.9-1,2-1.2,3.3
              c-0.2,1.2-0.2,2.6,0.1,4.1c0.2,1.5,0.7,2.8,1.3,3.9c0.6,1.1,1.3,2,2.2,2.7c0.9,0.7,1.9,1.2,3,1.4c1.1,0.3,2.3,0.3,3.6,0.1
              c1.3-0.2,2.4-0.6,3.4-1.2c1-0.6,1.8-1.4,2.4-2.3c0.6-0.9,1-2,1.2-3.3C481.1,779.9,481.1,778.6,480.8,777.1z"/>
            <path d="M482.4,764.4l2.6-0.6c0.3-0.1,0.5,0,0.7,0.1c0.2,0.1,0.4,0.3,0.5,0.5l10.7,15.3c0.2,0.3,0.5,0.7,0.7,1.1
              c0.2,0.4,0.5,0.8,0.7,1.3c0-0.5,0-1,0-1.5c0-0.5,0.1-0.9,0.1-1.3l2.8-18.4c0-0.2,0.1-0.4,0.3-0.6c0.1-0.2,0.4-0.3,0.6-0.4l2.6-0.6
              l-4.2,26.1l-2.9,0.7L482.4,764.4z"/>
            <path d="M521.6,754.1l0.8,2.6l-11.3,3.3l2.3,7.8l9.1-2.7l0.7,2.5l-9.1,2.7l2.4,8l11.3-3.3l0.8,2.6l-14.5,4.3l-7-23.5L521.6,754.1z"
              />
            <path d="M534.4,760c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.3,0.7-0.4
              c0.3-0.1,0.6-0.1,0.8-0.1c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0.3,0.1,0.6,0.1,0.9
              c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.7,0.4c-0.3,0.1-0.6,0.1-0.8,0.1c-0.3,0-0.5-0.1-0.8-0.2
              c-0.2-0.1-0.5-0.3-0.6-0.5C534.6,760.6,534.5,760.3,534.4,760z M538.7,772.1c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.3,0.1-0.5,0.2-0.8
              c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.3,0.7-0.4c0.3-0.1,0.6-0.1,0.8-0.1c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.5,0.3,0.7,0.5
              c0.2,0.2,0.3,0.5,0.4,0.7c0.1,0.3,0.1,0.6,0.1,0.9c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.7,0.4
              c-0.3,0.1-0.6,0.1-0.8,0.1c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.5-0.3-0.6-0.5C539,772.6,538.8,772.4,538.7,772.1z"/>
            <path d="M571.2,742l1.9,18.2l-2.7,1.2c-0.3,0.1-0.6,0-0.9-0.3l-7.6-8.5c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2-0.2-0.4-0.5-0.5-0.7
              c0.1,0.6,0.2,1.2,0.3,1.9l1.1,11.3c0.1,0.5-0.1,0.8-0.5,0.9l-2.6,1.1l-12.1-13.7l2.7-1.2c0.3-0.1,0.5-0.1,0.7-0.1
              c0.2,0.1,0.4,0.2,0.5,0.3l6.8,8.1c0.3,0.4,0.6,0.8,0.8,1.1c0.3,0.4,0.5,0.7,0.7,1.1c-0.1-0.4-0.1-0.9-0.2-1.3
              c-0.1-0.5-0.1-0.9-0.2-1.4l-1.1-10.7c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.4l1.5-0.6c0.2-0.1,0.5-0.1,0.7-0.1
              c0.2,0,0.4,0.2,0.5,0.3l7,8.1c0.3,0.4,0.6,0.7,0.9,1.1c0.3,0.4,0.6,0.7,0.8,1.1c-0.1-0.4-0.2-0.9-0.3-1.3c-0.1-0.4-0.2-0.9-0.3-1.4
              l-1.4-10.5c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.4,0.5-0.5L571.2,742z"/>
            <path d="M595,729.4l3.2,18l-2.6,1.4c-0.3,0.2-0.6,0.1-0.9-0.3l-8.2-8c-0.2-0.2-0.4-0.5-0.7-0.7c-0.2-0.2-0.4-0.5-0.6-0.7
              c0.2,0.6,0.3,1.2,0.5,1.8l2,11.2c0.1,0.5,0,0.8-0.4,1l-2.5,1.3l-13.1-12.8l2.6-1.4c0.2-0.1,0.5-0.2,0.7-0.1c0.2,0,0.4,0.1,0.6,0.3
              l7.3,7.6c0.3,0.4,0.6,0.7,0.9,1.1c0.3,0.3,0.6,0.7,0.8,1c-0.1-0.4-0.2-0.9-0.3-1.3c-0.1-0.4-0.2-0.9-0.3-1.4l-1.9-10.5
              c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.4,0.5-0.5l1.4-0.8c0.2-0.1,0.5-0.2,0.7-0.1c0.2,0,0.4,0.1,0.6,0.3l7.6,7.5c0.3,0.3,0.7,0.7,1,1
              c0.3,0.3,0.6,0.7,0.9,1c-0.1-0.4-0.3-0.8-0.4-1.3c-0.1-0.4-0.2-0.9-0.4-1.4l-2.2-10.4c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.4,0.5-0.5
              L595,729.4z"/>
            <path d="M617.9,715l4.6,17.7l-2.5,1.6c-0.3,0.2-0.6,0.1-1-0.2l-8.7-7.3c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.7
              c0.2,0.6,0.4,1.2,0.6,1.8l2.8,11.1c0.1,0.4,0,0.8-0.3,1l-2.4,1.5l-14-11.8l2.5-1.5c0.2-0.2,0.5-0.2,0.7-0.2c0.2,0,0.4,0.1,0.6,0.2
              l7.9,7c0.3,0.3,0.7,0.7,1,1c0.3,0.3,0.6,0.7,0.9,1c-0.1-0.4-0.3-0.9-0.4-1.3c-0.1-0.4-0.3-0.9-0.4-1.4l-2.7-10.4
              c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.2,0.2-0.4,0.4-0.5l1.4-0.9c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0.1,0.6,0.2l8.1,6.9
              c0.4,0.3,0.7,0.6,1.1,1c0.3,0.3,0.7,0.6,1,1c-0.2-0.4-0.3-0.8-0.5-1.3c-0.2-0.4-0.3-0.9-0.5-1.3l-2.9-10.2c-0.1-0.2,0-0.4,0-0.6
              c0.1-0.2,0.2-0.4,0.5-0.5L617.9,715z"/>
            <path d="M627.5,726.7c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.1-0.6,0-0.9c0.1-0.3,0.2-0.6,0.3-0.8c0.2-0.3,0.4-0.5,0.7-0.7
              c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.1,0.9,0c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.3,0.6,0.4,0.9
              c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.3-0.2,0.6-0.3,0.8c-0.2,0.3-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.1-0.9,0
              c-0.3-0.1-0.6-0.2-0.8-0.3C627.9,727.2,627.7,727,627.5,726.7z"/>
            <path d="M624.1,700.6l14.9,20.5l-3,2.2l-14.9-20.5L624.1,700.6z"/>
            <path d="M638.2,699.5c1-0.8,2.1-1.4,3.1-1.7c1.1-0.3,2.1-0.4,3.2-0.2c1.1,0.2,2.1,0.5,3.1,1.2c1,0.6,1.9,1.5,2.8,2.6
              c0.9,1.1,1.5,2.2,1.8,3.3c0.4,1.1,0.5,2.2,0.4,3.3c-0.1,1.1-0.4,2.1-1,3.1c-0.6,1-1.3,1.9-2.4,2.7c-1,0.8-2.1,1.4-3.2,1.7
              c-1.1,0.3-2.2,0.4-3.2,0.2c-1.1-0.2-2.1-0.5-3.1-1.2c-1-0.6-1.9-1.5-2.8-2.6c-0.9-1.1-1.5-2.2-1.8-3.3c-0.4-1.1-0.5-2.2-0.4-3.3
              c0.1-1.1,0.4-2.1,1-3.1C636.3,701.2,637.1,700.3,638.2,699.5z M647.5,711.4c1.2-1,1.8-2.1,1.8-3.4c0-1.3-0.7-2.7-1.9-4.3
              c-1.2-1.5-2.5-2.5-3.7-2.9c-1.3-0.4-2.5-0.1-3.7,0.9c-1.3,1-1.9,2.1-1.8,3.5c0,1.3,0.7,2.7,1.9,4.3c1.2,1.5,2.5,2.5,3.7,2.8
              C645,712.7,646.2,712.4,647.5,711.4z"/>
            <path d="M658.7,682.5l6.2,17.8l-2.5,2.2l-16.7-8.6l2.3-2c0.2-0.2,0.4-0.3,0.7-0.3c0.2,0,0.4,0,0.6,0.1l9.6,5.1
              c0.4,0.3,0.9,0.5,1.3,0.8c0.4,0.3,0.8,0.6,1.2,0.9c-0.2-0.4-0.5-0.8-0.7-1.3c-0.2-0.4-0.4-0.9-0.6-1.4l-3.7-10.2
              c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0.2-0.4,0.4-0.6L658.7,682.5z"/>
            <path d="M668.2,685.9c0.7,0.7,1.4,1.2,2.1,1.5c0.7,0.3,1.4,0.5,2,0.6c0.6,0,1.3-0.1,1.9-0.3c0.6-0.3,1.2-0.6,1.7-1.2
              c0.5-0.5,0.9-1,1.2-1.5c0.3-0.5,0.5-0.9,0.6-1.3c0.1-0.4,0.3-0.8,0.3-1.1c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.5-0.3,0.7-0.2l1.7,0.3
              c0,0.7,0,1.3-0.2,2c-0.2,0.6-0.4,1.2-0.7,1.8c-0.3,0.6-0.6,1.1-1,1.7c-0.4,0.5-0.8,1-1.3,1.4c-0.9,0.8-1.8,1.5-2.8,1.9
              c-1,0.4-2.1,0.6-3.2,0.6c-1.1,0-2.2-0.3-3.3-0.9c-1.1-0.5-2.2-1.4-3.2-2.5c-0.8-0.9-1.5-1.8-1.9-2.8c-0.4-1-0.7-2.1-0.7-3.1
              c0-1.1,0.2-2.1,0.6-3.1c0.4-1,1.1-2,2-2.9c0.8-0.7,1.6-1.3,2.5-1.7c0.9-0.4,1.8-0.6,2.8-0.5c0.9,0,1.9,0.3,2.8,0.7
              c0.9,0.4,1.8,1.1,2.7,2c0.4,0.4,0.6,0.7,0.7,1c0,0.2-0.1,0.5-0.3,0.7L668.2,685.9z M673,678.2c-0.4-0.4-0.9-0.8-1.4-1.1
              c-0.5-0.3-1-0.4-1.5-0.5c-0.5,0-1,0-1.6,0.2c-0.5,0.2-1,0.5-1.5,1c-1,0.9-1.5,1.9-1.5,3c0,1.1,0.4,2.2,1.2,3.3L673,678.2z"/>
            <path d="M683.6,679.8c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0-0.6,0.2-0.9s0.3-0.6,0.5-0.8
              c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6,0,0.9,0.2c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.5,0.5,0.8
              c0.1,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9s-0.3,0.6-0.5,0.8c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2
              c-0.3,0-0.6-0.1-0.9-0.2C684.1,680.2,683.8,680,683.6,679.8z"/>
            <path d="M683.3,662.5c-0.4-1.2-0.6-2.2-0.6-3.2c0.1-1,0.5-1.9,1.2-2.7c0.3-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.6-0.3,0.9-0.4l1.9,2.1
              c0.1,0.2,0.2,0.3,0.2,0.4c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.3,0.3-0.6,0.5c-0.3,0.2-0.5,0.4-0.8,0.7c-0.3,0.4-0.6,0.8-0.8,1.2
              c-0.2,0.4-0.2,0.8-0.2,1.2c0,0.4,0.1,0.9,0.2,1.3c0.1,0.5,0.3,0.9,0.6,1.4l8,7.3l-2.5,2.7l-12.8-11.8l1.4-1.6
              c0.3-0.3,0.5-0.4,0.7-0.4c0.2,0,0.5,0.1,0.8,0.3L683.3,662.5z"/>
            <path d="M695.2,658c0.8,0.6,1.5,1,2.3,1.3c0.7,0.3,1.4,0.4,2.1,0.4c0.6,0,1.3-0.2,1.8-0.5c0.6-0.3,1.1-0.8,1.6-1.3
              c0.5-0.5,0.8-1.1,1-1.6c0.2-0.5,0.4-1,0.5-1.4c0.1-0.4,0.2-0.8,0.2-1.1c0.1-0.3,0.2-0.6,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3l1.7,0.1
              c0.1,0.7,0.1,1.3,0,2c-0.1,0.6-0.2,1.3-0.5,1.9c-0.2,0.6-0.5,1.2-0.9,1.8c-0.3,0.6-0.7,1.1-1.1,1.5c-0.8,0.9-1.7,1.6-2.6,2.2
              c-1,0.5-2,0.8-3.1,0.9c-1.1,0.1-2.2-0.1-3.4-0.5c-1.2-0.4-2.3-1.1-3.5-2.1c-0.9-0.8-1.7-1.7-2.2-2.6c-0.6-1-0.9-2-1-3
              s0-2.1,0.3-3.2c0.3-1.1,0.9-2.1,1.7-3.1c0.7-0.8,1.5-1.5,2.3-1.9c0.9-0.5,1.8-0.7,2.7-0.8c0.9-0.1,1.9,0.1,2.9,0.4
              c1,0.3,1.9,0.9,2.9,1.7c0.4,0.4,0.7,0.7,0.7,0.9c0.1,0.2,0,0.5-0.2,0.7L695.2,658z M699.2,649.8c-0.5-0.4-1-0.7-1.5-0.9
              c-0.5-0.2-1-0.3-1.6-0.3c-0.5,0-1,0.1-1.5,0.4c-0.5,0.2-1,0.6-1.4,1.1c-0.9,1-1.3,2.1-1.2,3.1c0.1,1.1,0.6,2.1,1.5,3.2L699.2,649.8
              z"/>
            <path d="M694.4,632.5l19.8,15.7l-2.3,2.9l-19.8-15.7L694.4,632.5z"/>
            <path d="M705,635.7c-0.3-2.7,0.3-5.1,2-7.3c0.6-0.8,1.3-1.4,2-1.9c0.7-0.4,1.5-0.7,2.3-0.8c0.8-0.1,1.6,0,2.4,0.3
              c0.8,0.3,1.6,0.7,2.4,1.3l8.9,6.6l-1,1.3c-0.2,0.3-0.4,0.5-0.6,0.6c-0.2,0.1-0.5,0.1-0.8,0l-1.4-0.6c0.1,0.6,0.1,1.2,0.1,1.7
              c0,0.5-0.1,1.1-0.2,1.6c-0.1,0.5-0.3,1-0.5,1.5c-0.2,0.5-0.5,1-0.9,1.5c-0.4,0.6-0.9,1-1.4,1.4c-0.5,0.4-1.1,0.6-1.6,0.8
              c-0.6,0.1-1.2,0.1-1.8,0c-0.6-0.1-1.2-0.4-1.9-0.9c-0.5-0.4-1-0.9-1.3-1.6c-0.3-0.7-0.4-1.5-0.3-2.5c0.1-1,0.4-2.1,0.9-3.4
              c0.5-1.3,1.4-2.7,2.5-4.4l-0.9-0.7c-1-0.7-1.9-1.1-2.7-1s-1.5,0.5-2.1,1.4c-0.4,0.6-0.7,1.1-0.8,1.6c-0.1,0.5-0.2,0.9-0.3,1.3
              c0,0.4-0.1,0.8-0.1,1.1c0,0.3-0.1,0.6-0.3,0.9c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.1-0.7,0.1L705,635.7z M716.7,632.2
              c-0.7,1.1-1.3,2-1.7,2.9c-0.4,0.8-0.7,1.5-0.8,2.1c-0.1,0.6-0.1,1.1,0,1.5c0.1,0.4,0.4,0.7,0.7,1c0.3,0.2,0.6,0.4,1,0.5
              c0.3,0.1,0.6,0.1,0.9,0c0.3-0.1,0.6-0.2,0.8-0.4c0.3-0.2,0.5-0.5,0.7-0.8c0.6-0.8,0.9-1.5,1-2.3c0.1-0.8,0.1-1.6-0.1-2.5
              L716.7,632.2z"/>
            <path d="M717.3,618.3c-0.3-1-0.3-2.1-0.2-3c0.1-1,0.5-2,1.1-2.9c0.6-0.8,1.2-1.5,2-2c0.8-0.5,1.6-0.7,2.6-0.8
              c0.9-0.1,1.9,0.1,3,0.4c1,0.3,2.1,0.9,3.2,1.6c1.2,0.8,2.2,1.6,2.9,2.6c0.8,0.9,1.3,1.9,1.6,2.9c0.3,1,0.4,2,0.3,3
              c-0.1,1-0.5,2-1.2,2.9c-0.3,0.5-0.6,0.9-1,1.2c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.1-0.9,0.2-1.3,0.2l1,0.9
              c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.4-0.2,0.6l-1.3,2l-21.1-14l2-3.1L717.3,618.3z M726.3,624.3c0.8-0.1,1.5-0.3,2.1-0.6
              c0.6-0.4,1-0.8,1.4-1.4c0.8-1.2,1-2.4,0.6-3.7c-0.4-1.3-1.6-2.5-3.4-3.7c-1.7-1.1-3.1-1.6-4.2-1.6s-2.1,0.7-2.8,1.7
              c-0.5,0.8-0.8,1.5-0.8,2.3c0,0.8,0.1,1.6,0.4,2.5L726.3,624.3z"/>
            <path d="M736.6,612.5c-0.3-0.2-0.5-0.4-0.7-0.6c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.1-0.6,0-0.9c0-0.3,0.2-0.6,0.3-0.9
              c0.2-0.3,0.4-0.5,0.6-0.7c0.3-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.6-0.1,0.9,0c0.3,0,0.6,0.2,0.9,0.3c0.3,0.2,0.5,0.4,0.7,0.7
              c0.2,0.3,0.3,0.5,0.4,0.8c0.1,0.3,0.1,0.6,0,0.9s-0.2,0.6-0.3,0.9c-0.2,0.3-0.4,0.5-0.6,0.7c-0.3,0.2-0.5,0.3-0.8,0.4
              c-0.3,0.1-0.6,0.1-0.9,0C737.2,612.8,736.9,612.7,736.6,612.5z"/>
            <path d="M737.1,588.2c0.1,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.5,0.3-0.8,0.4
              c-0.3,0.2-0.6,0.4-0.9,0.7c-0.3,0.3-0.6,0.7-0.9,1.2c-0.4,0.7-0.6,1.4-0.7,2c0,0.7,0.1,1.3,0.3,1.9c0.3,0.6,0.7,1.2,1.2,1.7
              s1.3,1.1,2.1,1.5c0.9,0.5,1.7,0.8,2.4,1c0.8,0.2,1.5,0.2,2.1,0.1c0.7-0.1,1.2-0.3,1.8-0.7c0.5-0.4,1-0.9,1.3-1.5
              c0.3-0.6,0.6-1.2,0.6-1.6c0.1-0.5,0.1-0.9,0.1-1.2c0-0.4-0.1-0.7-0.1-0.9c0-0.3,0-0.5,0.1-0.7c0.1-0.3,0.4-0.4,0.6-0.4l1.7-0.2
              c0.2,0.6,0.4,1.2,0.4,1.9c0.1,0.6,0,1.2-0.1,1.8c-0.1,0.6-0.2,1.2-0.5,1.8c-0.2,0.6-0.5,1.1-0.8,1.7c-0.5,0.9-1.2,1.7-2,2.3
              c-0.8,0.6-1.7,1-2.7,1.3c-1,0.2-2.1,0.2-3.3,0c-1.2-0.2-2.4-0.6-3.6-1.3c-1.1-0.6-2.1-1.4-2.8-2.2c-0.8-0.9-1.3-1.8-1.7-2.8
              c-0.3-1-0.5-2.1-0.3-3.2c0.1-1.1,0.5-2.3,1.2-3.5c0.6-1.1,1.4-2,2.2-2.6c0.8-0.6,1.8-1.1,2.8-1.4L737.1,588.2z"/>
            <path d="M744.5,573.9c0.1,0.2,0.1,0.3,0.2,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.5,0.4c-0.2,0.1-0.5,0.3-0.7,0.5
              c-0.3,0.2-0.6,0.4-0.9,0.7c-0.3,0.3-0.6,0.7-0.9,1.3c-0.4,0.7-0.6,1.4-0.6,2.1c0,0.7,0.1,1.3,0.4,1.9c0.3,0.6,0.7,1.2,1.3,1.7
              c0.6,0.5,1.3,1,2.1,1.4c0.9,0.4,1.7,0.7,2.5,0.9c0.8,0.2,1.5,0.2,2.1,0c0.7-0.1,1.2-0.4,1.7-0.8c0.5-0.4,0.9-0.9,1.2-1.5
              c0.3-0.6,0.5-1.2,0.6-1.7c0-0.5,0-0.9,0-1.2c0-0.4-0.1-0.7-0.2-0.9s0-0.5,0.1-0.7c0.1-0.3,0.3-0.4,0.6-0.5l1.7-0.3
              c0.3,0.6,0.4,1.2,0.5,1.8c0.1,0.6,0.1,1.2,0,1.8c-0.1,0.6-0.2,1.2-0.4,1.8c-0.2,0.6-0.4,1.2-0.7,1.7c-0.5,1-1.1,1.8-1.9,2.4
              c-0.8,0.7-1.7,1.1-2.7,1.4c-1,0.3-2.1,0.3-3.3,0.2c-1.2-0.1-2.4-0.5-3.7-1.2c-1.1-0.6-2.1-1.3-2.9-2.1c-0.8-0.8-1.4-1.7-1.8-2.7
              c-0.4-1-0.5-2-0.5-3.2c0.1-1.1,0.4-2.3,1-3.5c0.6-1.1,1.3-2,2.1-2.7c0.8-0.7,1.7-1.2,2.7-1.5L744.5,573.9z"/>
          </g>
        </g>
      </g>
      {splitted.map((l, i) => (
        <text
          key={i}
          transform={`translate(109.06 ${lineIsZh ? zhLines[i] : enLines[i]})`}
          fontSize={lineIsZh ? 56.69 : 57}
          fontFamily={lineIsZh ? 'Noto Serif TC' : 'Lato'}
          letterSpacing={byIsZh && '.07em'}
        >
          {l}
        </text>
      ))}
      <text
        transform="translate(105.19 593.55)"
        fontSize={byIsZh ? 28.35 : 31.74}
        letterSpacing={byIsZh && '.07em'}
        fontFamily={!byIsZh && 'Lato'}
        fontStyle={!byIsZh && 'italic'}
      >
        {by}
      </text>
    </SVG>

  );
};

export default Word;
