import React from 'react';
import { graphql } from 'gatsby';

import Words from '../containers/Words';

const words = (props) => {
  return (
    <Words {...props} />
  );
};

export default words;

export const query = graphql`
query WordsData {
  allWordsCsv {
    edges {
      node {
        line
        by
      }
    }
  }
}
`
