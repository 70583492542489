import React, { PureComponent } from 'react';
import FadeIn from 'animate-css-styled-components/lib/Fade/In'
import BounceIn from 'animate-css-styled-components/lib/Bounce/In'
import random from 'lodash/random'
import get from 'lodash/get'

import Page from '../Page';
import Box from '../../components/Box';
import Button from '../../components/Button';
import PageStart from '../../components/PageStart';
import { mobileOrDesktop } from '../../components/ThemeProvider/theme'
import gaTrack from '../../utils/gaTrack'


import Word from './Word'
import ShapeButton from '../../components/ShapeButton';

class Words extends PureComponent {
  constructor(props) {
    super(props);
    this.dataLength = props.data.allWordsCsv.edges.length;
    this.state = {
      id: get(props.location, ['state', 'id'], this.getRandom()),
    }
  }

  getRandom = () => random(this.dataLength - 1)

  refresh = () => {
    let id = this.getRandom();
    while (id === this.state.id) {
      id = this.getRandom()
    }
    this.setState({ id })
    gaTrack({ category: '情話', label: '產生情話' })
  }

  share = (id) => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=https://love.relab.cc/words/${id + 1}/`)
    gaTrack({ category: '情話', label: `分享 - 第${id + 1}句` })
  }

  render() {
    const { id } = this.state;
    const word = this.props.data.allWordsCsv.edges[id].node;
    const left = (
      <Box>
        <PageStart
          firstTitle="一句情話生產器"
          descrip="這台生產器專門提供一句情話，不管感人的、幽默的、可愛的或是撩人的，希望能給要告白的人一點點靈感與支持！"
        />
        <Box
          mt={mobileOrDesktop('2em', '5em')}
          mb={mobileOrDesktop('2em', '0')}
          textAlign={mobileOrDesktop('center', 'unset')}
          is={BounceIn}
          delay="0.75s"
        >
          <Button onClick={this.refresh}>再給我一句情話</Button>
        </Box>
      </Box>
    )

    const right = (
      <Box textAlign="center" is={FadeIn}>
        <Box position="relative" mx={mobileOrDesktop('5%', '15%')}>
          <Word {...word} />
          <Box
            width={mobileOrDesktop('25%', '20%')}
            position="absolute"
            top="0"
            left="100%"
            transform="translate(-133%, -10%)"
          >
            <ShapeButton onClick={() => this.share(id)} text="分享情話" />
          </Box>
        </Box>
      </Box>
    )
    return (
      <Box>
        <Page left={left} right={right} />
      </Box>
    );
  }
}

export default Words;
